.staff {
	padding: 0;
	list-style: none;
	@extend %flexbox;
	@include flex-flow(wrap);
	text-align: center;
	li {
		padding: 30px 20px;
		box-sizing: border-box;
		width: 100%;

		@media #{$tablet} {
			@include flex(1, 1, 45%);
		}

		@media #{$desktop} {
			@include flex(1, 1, 29%);
		}

	}

	.square-image {
		width: 200px;
		height: 200px;
			img {
				border-radius: 200px;
			}
		}

	.name {
		font-size: 1.3em;
		margin-top: 20px;
	}

	.position {
		color: #666;
	}
}
