.editor-link, .nav-open nav .editor-link {
 	display: none;
	margin-top: 0;

	.btn {
		border: 0;
		border-radius: 2px;
		width: 100%;
		max-width: 500px;
		box-sizing: border-box;
		font-size: 2rem;
		text-decoration: none;
		padding: 10px 15px;
		margin: 0;
		font-size: 18px;
	}

	nav &, .btn {
		cursor: pointer;
		background-color: #f7e064;
		color: #333;
		box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #f4d525;
			color: #333;
		}
	}
}

.cms-editor-active .editor-link {
	display: block;
}

.cms-editor-active nav .editor-link {
	display: inline;
}
